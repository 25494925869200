<template>
  <div>
    <b-button
        variant="primary"
        @click="showSidebar=true"
    >
      <span class="text-nowrap">Nuevo Colaborador</span>
    </b-button>
    <b-sidebar
        v-model="showSidebar"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
    >


      <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Agregar Colaborador
        </h5>

          <feather-icon
                  class="ml-1 cursor-pointer"
                  icon="XIcon"
                  size="16"
                  @click="handleHideSidebar"
          />

      </div>
        <b-form
            class="p-2"
        >
          <!-- Identificacion -->
          <b-form-group
              label="Identificación"
              label-for="identificacion"
          >
            <b-form-input
                id="identificacion"
                autofocus
                trim
                maxlength="13"
                v-model="empleado.empIdentificacion"
            />
          </b-form-group>
          <!-- Nombres -->
          <b-form-group
              label="Nombres"
              label-for="nombres"
          >
            <b-form-input
                id="nombres"
                autofocus
                trim
                v-model="empleado.empNombres"
            />
          </b-form-group>
          <!-- Apellidos -->
          <b-form-group
              label="Apellidos"
              label-for="apellidos"
          >
            <b-form-input
                id="apellidos"
                autofocus
                trim
                v-model="empleado.empApellidos"
            />
          </b-form-group>
          <!-- Email -->
          <b-form-group
              label="Email"
              label-for="email"

          >
            <b-form-input
                id="email"
                autofocus
                trim
                v-model="empleado.empEmail"
            />
          </b-form-group>
          <!-- Telefono -->
          <b-form-group
              label="Teléfono"
              label-for="telefono"
          >
            <b-form-input
                id="telefono"
                autofocus
                trim
                v-model="empleado.empTelefono"/>

          </b-form-group>
          <!-- Cargo -->
          <b-form-group
              label="Cargo"
              label-for="cargo"
          >
            <v-select
                label="posNombre"
                :reduce="x => x.posId"
                v-model="empleado.posId"
                :options="fetchCargos"
                :clearable="false"
            />
          </b-form-group>
          <!-- Areas -->
          <b-form-group
              label="Áreas"
              label-for="areas"
          >
            <v-select
                label="secNombre"
                :reduce="x => x.secId"

                v-model="empleado.secId"
                :options="fetchAreas"
                :clearable="false"
            />

          </b-form-group>
          <!-- Oficina -->
          <b-form-group
              label="Oficina"
              label-for="oficina"
          >
            <v-select
                label="offNombre"
                :reduce="x => x.offId"
                v-model="empleado.offId"
                :options="fetchOficinas()"
                :clearable="false"
            />
          </b-form-group>

            <b-form-group
                    label="Fecha Ingreso"
                    label-for="fechaingreso"
            >

                <flat-pickr

                        v-model="empleado.empInicia"
                        class="form-control"
                        :config="configDate"
                />
            </b-form-group>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button

                variant="primary"
                class="mr-2"
                @click="handleSubmit"
                :disabled="isBusy"
            >
              <template v-if="isBusy">
                <b-spinner small ></b-spinner>
                Agregando..
              </template>
              <template v-else >
                Guardar
              </template>


            </b-button>
            <b-button

                type="button"
                variant="outline-secondary"
                @click="hide"
                :disabled="isBusy"
            >
              Cancelar
            </b-button>
          </div>
          <validation-errors v-if="errors" :errors="errors" />

        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>

import { BButton, BSidebar, BForm, BFormGroup, BFormInput, BSpinner } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ValidationErrors from '@/modules/components/ValidationErrors.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { Spanish } from "flatpickr/dist/l10n/es.js"
import flatPickr from 'vue-flatpickr-component'
import moment from "moment";
import { getUserData } from '@/modules/auth/utils'

export default {
  name: 'EmpleadoNuevo',
  components: {
    vSelect,
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BSpinner,
    ValidationErrors,
    flatPickr
  },
  data(){
    return {
      errors: null,
      showSidebar: false,
      posId: null,
      secId: null,
      offId: null,
      isBusy: false,
      empleado: {
        "posId": null,
        "secId": null,
        "offId": null,
        "empIdentificacion":null,
        "empNombres": null,
        "empApellidos": null,
        "empTelefono": null,
        "empEmail": null,
        "empEstado": null,
        "empInicia": moment().format('YYYY-MM-DD'),


      },
        configDate: {
            dateFormat: 'Y-m-d',
            altInput: true,
            altFormat: "F j, Y",
            locale: Spanish,
        },
    }
  },
  computed: {
    ...mapGetters('sga-module',['fetchCargos','fetchAreas','fetchOficinas']),
  },

  methods: {
    ...mapActions('empleado-module', [ 'createEmpleado','getEmpleados']),
      // reset los campos del formulario
    handleHideSidebar(){
          // inicializar
          this.resetForm()
          // ocultar
          this.showSidebar = false

    },
    resetForm(){
          Object.keys(this.empleado).forEach( key =>  this.empleado[ key ] = null )
    },
    async handleSubmit(){
      try {
          this.isBusy = true
          this.errors = null
          const user = getUserData()

          const formData = {
            ...this.empleado,
            userId: user.id
          }

          await this.createEmpleado(formData)
          this.isBusy = false

          await this.getEmpleados()

          this.$toast({
              component: ToastificationContent,
              props: {
                  title: 'Sistema',
                  icon: 'CheckIcon',
                  text: '¡Colaborador creado exitosamente!',
                  variant: 'success'
              },
          })
          // inicializar
          this.resetForm()
          this.showSidebar = false

      }catch(error){
        // errores de autenticacion
        if ( error.response.status == 422 ) {

          const  data  = error.response.data.errors

          // errores de validacion
          this.errors = data

        }
      }
      finally {
        this.isBusy = false
      }
    }
  },
  async created(){
  }


}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
