<template>

  <div>

          <empleados-filters />

    <template v-if="isLoading">

      <div class="text-center">
        <b-spinner variant="primary" label="Loading" />
      </div>

    </template>


    <template v-else>

      <!-- empty state: no existen datos-->
      <template v-if="isEmptyList">

        <b-card class="text-center">

          <feather-icon
              icon="InboxIcon"
              size="100"
              class="mr-50 mb-5"
          />
          <h2>No hay colaboradores para mostrar</h2>
          <p class="p-2">
            Actualmente no hay colaboradores para mostrar. <br>
            Si necesita agregar nuevos colaboradores, haga clic en el botón de abajo para crear una nuevo colaborador.
          </p>

          <empleado-nuevo/>


        </b-card>

      </template>

      <!-- sección de datos -->
      <template v-else>

          <b-card no-body
                  class="mb-0">
              <div class="m-2">
                  <b-row>
                      <b-col
                              cols="12"
                              md="6"
                              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                      >
                          <label>Mostrar</label>
                          <v-select
                                  v-model="perPage"
                                  :options="perPageOptions"
                                  :clearable="false"
                                  class="per-page-selector d-inline-block mx-50"
                          />
                          <label>registros</label>
                      </b-col>
                      <b-col cols="12"
                             md="6"
                      >
                          <div class="d-flex align-items-center justify-content-end">
                              <b-form-input
                                      v-model="searchQuery"
                                      class="d-inline-block mr-1"
                                      placeholder="Buscar nombres..."
                              />
                              <empleado-nuevo></empleado-nuevo>

                          </div>

                      </b-col>
                  </b-row>

              </div>
              <b-table
                      class="position-relative"
                      :items="filteredData"
                      :fields="fields"
                      :current-page="currentPage"
                      :per-page="perPage"
                      responsive
                      show-empty
              >
                  <!-- Nombres Completos -->
                  <template #cell(empNombres)="data">
                      <router-link :to="{name: 'catalogo-empleados-view',query: {uuid: data.item.empUuid} }" >
            <span>
              {{ data.item.empNombres }} {{ data.item.empApellidos }}
            </span>
                      </router-link>
                  </template>
                  <!-- Telefono y Correo -->
                  <template #cell(empEmail)="data">
          <span>
            {{ data.item.empEmail }}<br> <small>{{ data.item.empTelefono }}</small>
          </span>
                  </template>
                  <!-- Cambia diseño de estado -->
                  <template #cell(empEstado)="data">
                      <b-badge
                              pill
                              :variant="`light-${resolveStatusVariant(data.item.empEstado)}`"
                              class="text-capitalize"
                      >
                          {{ resolveNameStatus(data.item.empEstado) }}
                      </b-badge>
                  </template>
              </b-table>

              <!-- pagination -->
              <div class="mx-2 mb-2">
                  <b-row>
                      <b-col
                              cols="12"
                              sm="6"
                              class="d-flex align-items-center justify-content-center justify-content-sm-start"
                      >
                          <span class="text-muted">{{ totalRecords }} registros encontrados</span>
                      </b-col>
                      <b-col
                              cols="12"
                              sm="6"
                              class="d-flex align-items-center justify-content-center justify-content-sm-end"
                      >
                          <b-pagination
                                  v-model="currentPage"
                                  :total-rows="totalRecords"
                                  :per-page="perPage"
                                  first-number
                                  last-number
                                  class="mb-0 mt-1 mt-sm-0"
                                  prev-class="prev-item"
                                  next-class="next-item"
                          >
                              <template #prev-text>
                                  <feather-icon
                                          icon="ChevronLeftIcon"
                                          size="18"
                                  />
                              </template>
                              <template #next-text>
                                  <feather-icon
                                          icon="ChevronRightIcon"
                                          size="18"
                                  />
                              </template>
                          </b-pagination>

                      </b-col>
                  </b-row>

              </div>
          </b-card>

      </template>
    </template>

  </div>

</template>

<script>
import EmpleadoNuevo from '@/modules/ejecutante/views/catalogos/empleados/EmpleadoNew.vue'
import EmpleadosFilters from '@/modules/ejecutante/views/catalogos/empleados/EmpleadosFilters.vue'
import {BCard, BTable, BRow, BCol, BButton, BFormInput, BBadge, BPagination, BSpinner} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions,mapGetters } from 'vuex'
import {resolvePerPageOptions} from "@/modules/common/utils";

export default {
  name: 'EmpleadosList',
  components: {
      BSpinner,
    BPagination,
    EmpleadoNuevo,
    EmpleadosFilters,
    vSelect,
    BCard,
    BTable,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BBadge

  },
  data(){
    return{
      isLoading: false,
      fields: [

        { key: 'empNombres', label: 'Nombres', sortable: true },
        { key: 'empEmail', label: 'Email', sortable: true },
        { key: 'posNombre', label: 'Cargo', sortable: true },
        { key: 'offNombre', label: 'Oficina', sortable: true },
        { key: 'empEstado', label: 'Estado', sortable: true },

      ],
      items: [],
      perPage: 10,
      perPageOptions: resolvePerPageOptions(),
      currentPage: 1,
      totalRecords: 0,
      searchQuery: '',
    }
  },
  computed: {
    ...mapGetters('empleado-module', ['fetchEmpleados']),
    filteredData(){
        const data = this.fetchEmpleados( this.searchQuery )
        this.totalRecords = data.length
        return data
    },

    // verificar que existan datos originales (antes de filtros)
    isEmptyList(){
      return this.fetchEmpleados().length === 0
    }

  },
  methods: {
    ...mapActions('empleado-module', ['getEmpleados']),


    resolveStatusVariant(estado){
      console.log(estado)
      if ( estado == "1" ) return "success"
      return "secondary"
    },
    resolveNameStatus(estado){
      if ( estado == "1" ) return "activo"
      return "inactivo"
    }
  },
  async created() {
    this.isLoading = true
    await this.getEmpleados()
    this.isLoading = false
  },


}
</script>

<style scoped>

</style>
