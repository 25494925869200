<template>
<b-card>
  <b-card-header>
    <h5>Colaborador</h5>
  </b-card-header>
  <b-card-body>
    <b-row>
      <b-col cols="12"
             md="4"
             class="mb-md-0 mb-2">
        <label>Cargo</label>
        <v-select
            v-model="typeCargosFilter"
            label="posNombre"
            :options="optionsCargos"
            class="w-100"
            :reduce="option => option.posId"
            :searchable="false"
            @input="updateFilters"
        />

      </b-col>
      <b-col cols="12"
             md="4"
             class="mb-md-0 mb-2">
        <label>Areas</label>
        <v-select
            v-model="typeAreasFilter"
            label="secNombre"
            :options="optionsAreas"
            class="w-100"
            :reduce="option => option.secId"
            :searchable="false"
            @input="updateFilters"
        />
      </b-col>
      <b-col cols="12"
             md="4"
             class="mb-md-0 mb-2">
        <label>Oficina</label>
        <v-select
            v-model="typeOficinasFilter"
            label="offNombre"
            :options="optionsOficinas"
            class="w-100"
            :reduce="option => option.offId"
            :searchable="false"
            @input="updateFilters"
        />
      </b-col>
    </b-row>
  </b-card-body>
</b-card>

</template>

<script>

import { BCard, BCardBody, BCardHeader, BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'
import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
  name: 'EmpleadosFilters',
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
    vSelect
  },

  data(){
    return {
      posId: null,
      secId: null,
      offId: null,
      optionsCargos: [],
      optionsAreas: [],
      optionsOficinas: [],
      typeCargosFilter: null,
      typeAreasFilter: null,
      typeOficinasFilter: null
    }
  },
  computed: {
    ...mapGetters('sga-module',['fetchCargos','fetchAreas','fetchOficinas'])
  },

  methods: {
    ...mapActions('sga-module',['getCargos','getAreas','getOficinas']),
    ...mapMutations('empleado-module', ['setFiltersCriteria']),

    async fillFilters(){
      //await Promise.all([this.getCargos,this.getAreas,this.getOficinas])
      await this.getCargos()
      await this.getAreas()
      await this.getOficinas()
      this.optionsCargos = this.fetchCargos
      this.optionsAreas = this.fetchAreas
      this.optionsOficinas = this.fetchOficinas()
    },

      updateFilters(){
          const filtersCriteria = []
          if ( this.typeCargosFilter != null )
              filtersCriteria.push( { field: 'posId', values: [ this.typeCargosFilter ] } )
          if ( this.typeAreasFilter != null )
              filtersCriteria.push( { field: 'secId', values: [ this.typeAreasFilter ] } )
          if ( this.typeOficinasFilter != null )
              filtersCriteria.push( { field: 'offId', values: [ this.typeOficinasFilter ] } )

          this.setFiltersCriteria( filtersCriteria )

      }

  },
  async created(){
   await this.fillFilters()
  }

}





</script>

<style scoped>

</style>
