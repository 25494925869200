<template>

  <div>
    <empleados-list></empleados-list>
  </div>
</template>

<script>
import EmpleadosList from '@/modules/ejecutante/views/catalogos/empleados/EmpleadosList.vue'
export default {
  name: 'Empleados',
  components: {
    EmpleadosList
  }
}
</script>

<style scoped>

</style>
